import { useCompany } from '@/hooks/use-company';
import isNil from '@/utils/is-nil';

const FooterCompanyInfo = () => {
    const { data: company, isLoading, error } = useCompany();

    if (isNil(company) || isLoading || error) {
        return null;
    }

    return (
        <div className="flex flex-col gap-x-10 gap-y-6 lg:flex-row">
            <div className="flex flex-col gap-1">
                <h4 className="text-sm font-semibold">Dane Firmowe</h4>
                <ul className="space-y-1 text-sm text-muted-foreground">
                    <li>{company.name}</li>
                    <li>NIP: {company.nip}</li>
                    <li>REGON: {company.regon}</li>
                </ul>
            </div>
            <div className="flex flex-col gap-1">
                <h4 className="text-sm font-semibold">Dane Kontaktowe</h4>
                <ul className="space-y-1 text-sm text-muted-foreground">
                    <li>
                        <a target="_blank" href={company.address?.url} rel="noreferrer">
                            {company.address?.label}
                        </a>
                    </li>
                    <li>
                        <a href={`mailto:${company.contact?.email}`}>{company.contact?.email}</a>
                    </li>
                    <li>
                        <a href={`tel:${company.contact?.phone}`}>{company.contact?.phone}</a>
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-1">
                <h4 className="text-sm font-semibold">Social Media</h4>
                <ul className="space-y-1 text-sm text-muted-foreground">
                    {company.socialMedia?.map((item) => (
                        <li key={item._key}>
                            <a target="_blank" href={item.url} rel="noreferrer">
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FooterCompanyInfo;
