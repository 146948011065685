import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UIState = {
    isLoading: boolean;
};

const initialState: UIState = {
    isLoading: false,
};

export const uiSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<UIState['isLoading']>) => {
            state.isLoading = action.payload;
        },
    },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
