import { FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/shadcn-utils';
import { ChangeEvent } from 'react';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

const PhoneInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: UseControllerProps<TFieldValues, TName>) => {
    const formatInputValue = (event: ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value.replace(/[^\d+]/g, '');

        inputValue = inputValue
            .replace(/(\d{3})(\d)/, '$1 $2')
            .replace(/(\d{3})(\d)/, '$1 $2')
            .replace(/(\d{3})(\d)/, '$1 $2');

        return inputValue;
    };

    return (
        <FormField
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel required>Numer telefonu</FormLabel>
                    <FormControl>
                        <div className="grid grid-cols-[auto,1fr]">
                            <div
                                className={cn(
                                    'flex w-fit items-center justify-center rounded-l bg-input px-3.5 text-sm text-muted-foreground',
                                    field.disabled && 'opacity-25',
                                )}
                            >
                                +48
                            </div>
                            <Input
                                type="tel"
                                autoComplete="tel"
                                placeholder="000 000 000"
                                minLength={11}
                                maxLength={11}
                                value={field.value}
                                ref={field.ref}
                                name={field.name}
                                disabled={field.disabled}
                                onBlur={field.onBlur}
                                onChange={(e) => {
                                    const value = formatInputValue(e);
                                    field.onChange(value);
                                }}
                                className="rounded-none rounded-r"
                            />
                        </div>
                    </FormControl>
                </FormItem>
            )}
        />
    );
};

export default PhoneInput;
