import { Review as ReviewType } from '@/models/review';
import { cn } from '@/shadcn-utils';
import { StarIcon } from 'lucide-react';
import { ComponentPropsWithoutRef } from 'react';

type ReviewProps = ComponentPropsWithoutRef<'div'> & {
    review: ReviewType;
};

const Review = ({ review, className, ...props }: ReviewProps) => {
    const ratings = new Array(5).fill(0).map((_, index) => (index < review.rating ? 1 : 0));

    return (
        <div
            className={cn(
                'flex select-none flex-col gap-4 rounded border-2 bg-card p-6 lg:p-10',
                className,
            )}
            {...props}
        >
            <div className="flex gap-4">
                <a
                    href={review.authorAttribution.uri}
                    target="_blank"
                    className="size-10 shrink-0"
                    rel="noreferrer"
                >
                    <img
                        src={review.authorAttribution.photoUri}
                        alt={review.authorAttribution.displayName}
                        className="aspect-square size-full"
                    />
                </a>
                <div className="flex flex-1 flex-col gap-1">
                    <div className="font-medium">{review.authorAttribution.displayName}</div>
                    <div className="flex items-center gap-0.5">
                        {ratings.map((rating, index) => (
                            <StarIcon
                                key={index}
                                size={10}
                                className={cn(
                                    rating === 0
                                        ? 'fill-white/15 stroke-white/15'
                                        : 'fill-yellow-500 stroke-yellow-500',
                                )}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <p className="line-clamp-5 text-muted-foreground">&apos;{review.text.text}&apos;</p>
        </div>
    );
};

export default Review;
