import EmailInput from '@/components/contact-form/email-input';
import { ContactFormSchema, ContactFormSchemaType } from '@/components/contact-form/form-schema';
import MessageInput from '@/components/contact-form/message-input';
import NameInput from '@/components/contact-form/name-input';
import PhoneInput from '@/components/contact-form/phone-input';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { MailingService } from '@/services/mailing';
import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { AsteriskIcon } from 'lucide-react';
import { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';

type ContactFormProps = {
    onSubmitSuccess?: () => void;
};

const ContactForm = forwardRef<HTMLFormElement, ContactFormProps>(({ onSubmitSuccess }, ref) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { toast } = useToast();

    const form = useForm<ContactFormSchemaType>({
        resolver: zodResolver(ContactFormSchema),
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        disabled: isSubmitting,
    });

    const handleOnSubmit = async (value: ContactFormSchemaType) => {
        try {
            setIsSubmitting(true);

            await MailingService.sendMail({
                name: value.name,
                email: value.email,
                phone: value.phone,
                message: value.message,
            });

            setIsSubmitting(false);
            onSubmitSuccess?.();
            form.reset();
        } catch (error) {
            toast({
                variant: 'destructive',
                title: 'Błąd podczas wysyłania wiadomości!',
                description:
                    'Coś poszło nie tak podczas wysyłania wiadomości. Prosimy spróbować jeszcze raz za moment.',
            });

            setIsSubmitting(false);
        }
    };

    return (
        <Form {...form}>
            <form
                ref={ref}
                onSubmit={form.handleSubmit(handleOnSubmit)}
                className="grid grid-cols-1 grid-rows-[auto,auto,auto] gap-y-4"
            >
                <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:grid-rows-1">
                    <NameInput control={form.control} name="name" />
                    <EmailInput control={form.control} name="email" />
                    <PhoneInput control={form.control} name="phone" />
                </div>
                <MessageInput control={form.control} name="message" />
                <div className="flex select-none items-center gap-1 text-xs font-medium text-foreground/50">
                    <AsteriskIcon size={11} absoluteStrokeWidth strokeWidth={1.25} />
                    Wszystkie powyższe pola są wymagane, aby wysłać zapytanie
                </div>
                <Button
                    type="submit"
                    size="lg"
                    disabled={!form.formState.isValid || isSubmitting}
                    className="md:max-w-fit"
                >
                    Wyślij wiadomość
                </Button>
            </form>
        </Form>
    );
});

ContactForm.displayName = 'ContactForm';

export const MotionContactForm = motion(ContactForm);

export default ContactForm;
