import { z } from 'zod';

export const ContactFormSchema = z.object({
    name: z.string().min(3).max(100),
    email: z.string().min(3).max(100).email(),
    phone: z.string().min(3).length(11),
    message: z.string().min(3).max(255),
});

export type ContactFormSchemaType = z.infer<typeof ContactFormSchema>;
