import { SanityCMSClient } from '@/api-clients/sanity-client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(SanityCMSClient);

export function getSanityImageUrl(source: SanityImageSource): ImageUrlBuilder {
    return builder.image(source);
}
