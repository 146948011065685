import { FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { Textarea } from '../ui/textarea';

const MessageInput = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: UseControllerProps<TFieldValues, TName>) => {
    return (
        <FormField
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <FormItem className="col-span-2 md:col-span-1">
                    <FormLabel required>Wiadomość</FormLabel>
                    <FormControl>
                        <Textarea rows={7} placeholder="Wprowadź swoją wiadomość..." {...field} />
                    </FormControl>
                </FormItem>
            )}
        />
    );
};

export default MessageInput;
